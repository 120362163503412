<template>
  <div>
    <div class="d-flex align-items-center pb-3" v-if="tutors.length === 0">
      <router-link to="/groups">
        <img src="@/assets/images/arrow.png" style="width: 40px" />
      </router-link>
      <h2 class="pl-5">No tutor that have applied for this group</h2>
    </div>
    <div v-if="tutors.length" class="d-flex align-items-center pb-3">
      <router-link to="/groups">
        <img src="@/assets/images/arrow.png" style="width: 40px" />
      </router-link>
      <h2 class="pl-5">Please select tutor to assign to group</h2>
    </div>
    <b-row v-if="tutors.length">
      <template v-for="(tutor, index) in tutors">
        <b-col cols="4" :key="index">
          <b-card
            :title="tutor.name"
            img-src="/img/silhouette_large.png"
            img-alt="Image"
            img-top
            tag="article"
            style="max-width: 15rem"
            class="mb-2"
          >
            <b-card-text> Rate : ${{ tutor.hourlyRate }}.00 </b-card-text>
            <b-button
              variant="primary"
              @click="assignTutor(tutor.organizationId)"
              >Select</b-button
            >
          </b-card>
        </b-col>
      </template>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  methods: {
    ...mapActions("job", ["getAssignedTutors", "selectTutor"]),
    ...mapActions('posts', [
      'selectRoom',
    ]),
    async assignTutor(id) {
      const query = this.$route.query;
      const data = {
        tutorId: id,
        roomId: query.id,
      };
      await this.selectTutor(data);
      await this.selectRoom(query.id)
      this.$bvToast.toast(`Tutor selected successfully`, {
        title: "Success",
        autoHideDelay: 5000,
        // appendToast: append,
      });
      this.$router.push({
                path: '/portal/group/main',
                query: { id: this.$route.query.id },
              });
    },
  },
  computed: {
    ...mapState({
      tutors: (state) => state.job.tutors,
    }),
  },
  mounted() {
    const query = this.$route.query;
    this.getAssignedTutors(query.id);
  },
};
</script>

<style>
</style>
